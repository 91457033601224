globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"BagL3sck8vIPQKF8GI_oO"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  environment: process.env.APP_ENV,
  dsn: "https://b5cff6962bb167b184e69dd60cfc2971@o4504831428919296.ingest.sentry.io/4505770499178496",
  // Replay may only be enabled for the client-side
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
